import type { PageProps } from 'gatsby'
import React from 'react'
import EnterpriseFitnessLanding from 'enterprise/fitness/components/EnterpriseFitnessLanding'
import * as enterprise from 'libs/enterprise'
import { ReactComponent as LogosSvg } from './images/logos.svg'

type Props = PageProps

export default function EnterpriseCuttlefishAlignSeniorCare(props: Props) {
  return (
    <EnterpriseFitnessLanding
      {...props}
      logos={LogosSvg}
      partner={enterprise.CUTTLEFISH_ALIGN_KEY}
      className="EnterpriseCuttlefishAlignSeniorCare"
    />
  )
}
